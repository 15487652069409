.about-page {
  .about-page-title {
    font-size: 40px;
    font-family: 'Coolvetica';
    color: #2e2d2d;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
  
    // &:before {
    //   content: '<h1>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: 160px;
    //   opacity: 0.6;    line-height: 18px;
    // }
  
    // &:after {
    //   content: '<h1/>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: 140px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }
  
  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: auto;
    text-align: center; 
    font-family: 'Quicksand';

    p {
      font-size: 19px !important;
      color: #2e2d2d !important;
      font-family: 'Quicksand';
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
  // .text-animate-hover {
  //   &:hover {
  //     color: red;
  //   }
  // }
}


@media screen and (max-width: 1200px) {
  .about-page {
    // .about-page-title {
    //   &:before {
    //     font-size: 14px !important;
    //     left: 50px !important;
    //   }

    //   &:after {
    //     font-size: 14px !important;
    //     left: 30px !important;
    //   }
    // }

    .text-zone {
      p {
        font-size: 16px !important;
      }
    }
  }
}

// .text-animate-hover {
//   &:hover {
//     color: #2e2d2d;
//   }
// }

// @media screen and (max-width: 1200px) {
//   .service-page-title {
//     &:before {
//       left: 50px !important;
//     }

//     &:after {
//       left: 30px !important;
//     }
//   }
// }
/* Grid container for 2x2 layout */


.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Default: Two-column layout
  align-items: stretch; // Ensure equal height for all items
  gap: 0; // Remove gap to prevent border misalignment
  width: 100%;
  border-collapse: collapse; // Helps maintain structure
}

/* Each service item with transparent background */
.service-item {
  background: transparent;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; // Ensures borders don't shift layout
  width: 100%;
}

/* Add vertical & horizontal borders only in desktop view */
@media screen and (min-width: 768px) {
  .service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .service-item:nth-child(odd)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(46, 45, 45, 0.3); // Reduced opacity
  }

  .service-item:nth-child(-n+2)::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(46, 45, 45, 0.3); // Reduced opacity
  }
}

/* Single-column layout on small screens (no borders) */
@media screen and (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; // Switch to single column
  }

  .service-item {
    border: none; // Remove all borders
  }
}

/* Flex container for icon and heading on the same line */
.service-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Icon styling */
.service-icon {
  font-size: 20px;
  color: #2e2d2d;
}

/* Service title styling */
.service-item h2 {
  color: #2e2d2d;
  font-size: 18px;
  margin: 0;
}

/* Service description */
.service-item p {
  font-size: 14px;
  color: #2e2d2d;
  margin: 0;
}


