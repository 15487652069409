.about-page {
  .about-page-title {
    font-size: 40px;
    font-family: 'Coolvetica';
    color: #2e2d2d;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
  
    // &:before {
    //   content: '<h1>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: 160px;
    //   opacity: 0.6;    line-height: 18px;
    // }
  
    // &:after {
    //   content: '<h1/>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: 140px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }
  
  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: auto;
    text-align: center; 
    font-family: 'Quicksand';

    p {
      font-size: 19px !important;
      color: #2e2d2d !important;
      font-family: 'Quicksand';
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
  // .text-animate-hover {
  //   &:hover {
  //     color: red;
  //   }
  // }
}


@media screen and (max-width: 1200px) {
  .about-page {
    // .about-page-title {
    //   &:before {
    //     font-size: 14px !important;
    //     left: 50px !important;
    //   }

    //   &:after {
    //     font-size: 14px !important;
    //     left: 30px !important;
    //   }
    // }

    .text-zone {
      p {
        font-size: 16px !important;
      }
    }
  }
}



.about-page {
  .text-zone {
    .developers-container {
      display: flex;
      justify-content: center;
      gap: 10px; // Default gap for larger screens
      margin-top: 20px;
      text-align: center;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        gap: 20px; // Increase gap for mobile screens
        row-gap: 30px; // Additional spacing between wrapped items
      }

      .developer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        min-width: 250px;

        .developer-image {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          object-fit: cover;
          transform: scaleX(-1);
          margin-bottom: 10px;

          @media screen and (max-width: 768px) {
            width: 100px; // Reduce image size on small screens
            height: 100px;
          }
        }

        .developer-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .developer-name {
            font-size: 20px;
            font-weight: bold;
            color: #2e2d2d;
            margin-bottom: 0px;
          }

          .developer-role {
            font-size: 1rem;
            color: #555;
            font-weight: 500;
            margin-top: 5px;
            margin-bottom: 0px;
          }

          .developer-contact {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #666;
            font-size: 18px;
            margin-bottom: 0px;
            flex-wrap: wrap;
            gap: 5px;

            .email-icon {
              width: 18px;
              height: 18px;
              margin-right: 5px;
              fill: #555;
            }
          }

          .linkedin-btn {
            display: inline-block;
            padding: 8px 15px;
            background: #2e2d2d;
            color: white;
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            border-radius: 5px;
            margin-top: 10px;
            transition: background 0.3s ease-in-out;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .about-summary {
      margin-top: 30px !important;
      margin-bottom: 30px;
      font-size: 16px !important;
      color: #333;
      text-align: center;
    }
  }
}
