.contact-form {
  width: 60%;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }

    p{
      color:#333333;
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 1px solid #333333;
    background: white;
    height: 50px;
    font-size: 16px;
    color: #333333;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  textarea {
    width: 100%;
    border: 1px solid #333333;
    background: white;
    height: 50px;
    font-size: 16px;
    color: #333333;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 5px;
  }

  .flat-button {
    color: #333333;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #333333;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
    cursor: pointer !important;
  }
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.0s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .contact-form {
    width: 90%;
    margin-top: 15%;
    margin-left: 5%;
  }
 
}

.contact-page {
  .contact-page-title {
    font-size: 40px;
    font-family: 'Coolvetica';
    color: #2e2d2d;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
  
    // &:before {
    //   content: '<h1>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: 160px;
    //   opacity: 0.6;    line-height: 18px;
    // }
  
    // &:after {
    //   content: '<h1/>';
    //   font-family: 'Quicksand';
    //   color: #333333;
    //   font-size: 16px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: 140px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }
  
  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: auto;
    text-align: center; 
    font-family: 'Quicksand';

    p {
      font-size: 19px !important;
      color: #2e2d2d !important;
      font-family: 'Quicksand';
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
  // .text-animate-hover {
  //   &:hover {
  //     color: red;
  //   }
  // }
}


@media screen and (max-width: 1200px) {
  .contact-page {
    // .about-page-title {
    //   &:before {
    //     font-size: 14px !important;
    //     left: 50px !important;
    //   }

    //   &:after {
    //     font-size: 14px !important;
    //     left: 30px !important;
    //   }
    // }

    .text-zone {
      p {
        font-size: 16px !important;
      }
    }
  }
}
