.home-page {
  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    text-align: center; /* Optional: centers the text within the container */

    .flat-home-button {
      color: #333333 !important;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #333333 !important;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
      top: 50px;
  
      &:hover {
        background: #333333 !important;
        color: white !important;
      }
    }

    h2 {
      color: #2e2d2d;
      margin-top: 10px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }
  }

  .introtext {
    color: #2e2d2d;
    font-size: 50px !important;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    text-align: center;
    white-space:inherit;

    .logo-class{
      font-size: 70px !important;
    }
  }
  
  // .text-animate-hover {
  //   &:hover {
  //     color: gray;
  //   }
  // }
}

/* For large screens, keep the entire text on one line */
.animated-letters-container {
  white-space: nowrap;
}

/* Each word remains together */
.word-container {
  white-space: nowrap;
}

/* For small screens, allow wrapping at word boundaries */
@media screen and (max-width: 768px) {
  .animated-letters-container {
    white-space: normal;
  }
}

canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; // Send it behind other content
}

@media screen and (max-width: 1200px) {

  .introtext {

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

  }
}
